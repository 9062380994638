<template>

	<div style="max-width: 600px;margin: 0 auto">

		<van-image :src="require('@/assets/images/download-bg.png')"></van-image>

		<div style="margin: 0 auto;padding: 20px 0; width: 230px">
			<div>
				<van-image :src="require('@/assets/images/android-download-lc.png')" @click="androidDownload"></van-image>
			</div>
		</div>

		<van-overlay :show="isWeiXin">
			<div class="wrapper">
				<div class="weixin-browser"></div>
			</div>
		</van-overlay>
	</div>

</template>

<script>
	export default {
		name: 'download_android',
		data() {
			return {
				resourceURL: this.$axios.defaults.resourceURL,
				
				isWeiXin: false,
			}
		},

		mounted() {
			document.title = this.translate('android_app_download')

			var ua = window.navigator.userAgent.toLowerCase();

			//通过正则表达式匹配ua中是否含有MicroMessenger字符串
			if(ua.match(/MicroMessenger/i) == 'micromessenger'){
				this.isWeiXin = true
			} else{
				this.isWeiXin = false
			}
		},
		
		methods: {
			androidDownload() {
				window.location.href= this.$axios.defaults.baseURL + 'download_download/android'
			},
		},

	}
</script>